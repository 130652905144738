import React, { useState, useEffect } from "react";
//import { Link } from "react-router-dom";

interface Item {
	id: string;
	name: string;
	description: string;
}

const HomePage: React.FC = () => {
	const [items, setItems] = useState<Item[]>([]);

	useEffect(() => {
		fetch("/data.json")
			.then((response) => response.json())
			.then((data) => setItems(data))
			.catch((error) => console.error("Error loading the data:", error));
	}, []);

	return (
		<div>
			<h1 className="text-2xl font-bold">Items</h1>
			<ul>
				{items.map((item) => (
					<li key={item.id}>
						{/*
						<Link
							to={`/item/${item.id}`}
							className="text-blue-500 hover:text-blue-800"
						>
							{item.name}
						</Link>
                         */}
						You need proper access to view these items.
					</li>
				))}
			</ul>
		</div>
	);
};

export default HomePage;
