import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Header from "../components/Header";
import Description from "../components/Description";
import ScrollingSection from "../components/ScrollingSection";
import Item from "../types/Item";

const ItemPage: React.FC = () => {
	const { id } = useParams();
	const [item, setItem] = useState<Item | null>(null);

	useEffect(() => {
		fetch("/data.json")
			.then((response) => response.json())
			.then((items) => setItem(items.find((item: Item) => item.id === id)))
			.catch((error) => console.error("Error loading the item:", error));
	}, [id]);
	return (
		<div className="container mx-auto p-4">
			<Header
				title={item ? item.name + " Product Passports" : "Product Passports"}
			/>
			<Description text="Find all the product passports and labels we can generate for your product." />
			<Header title="Product Labels" />
			<Description text="Smaller product labels that highlight individual claims about this product's ethics and sustainability." />
			{item && <ScrollingSection item={item} passport={false} />}
			<Header title="Product Passports" />
			<Description text="Larger, more holistic product passports about a product's life cycle impact." />
			{item && <ScrollingSection item={item} passport={true} />}
			<div className="h-60"></div>
		</div>
	);
};

export default ItemPage;
