import React from "react";

interface HeaderProps {
	title: string;
}

const Header: React.FC<HeaderProps> = ({ title }) => {
	return <header className="text-2xl font-bold my-4">{title}</header>;
};

export default Header;
