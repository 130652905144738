import Item from "../types/Item";

const ProductPassport: React.FC<{ item: Item }> = ({ item }) => {
	return (
		<div className="flex justify-center items-center min-h-screen bg-gray-100">
			{item ? (
				<div className="max-w-md w-full bg-white rounded-lg shadow-md p-4">
					<img
						src={item.image_url}
						alt={item.name}
						className="w-full h-64 object-cover rounded-t-lg"
					/>
					<div className="p-4">
						<h2 className="text-xl font-bold">{item.name}</h2>
						<p className="text-sm text-gray-600">Brand: {item.brand}</p>
						<div className="mt-2">
							<h3 className="font-semibold">Materials</h3>
							{Object.keys(item.materials).map((material) => (
								<p key={material} className="text-sm">
									{material}: {(item.materials[material] * 100).toFixed(2)}%
								</p>
							))}
						</div>
						<div className="mt-2">
							<h3 className="font-semibold">Verified Certifications</h3>
							<ul className="list-disc pl-5">
								{item.certifications.map((cert) => (
									<li key={cert} className="text-sm">
										{cert}
									</li>
								))}
							</ul>
						</div>
						<p className="mt-2">
							Carbon Footprint:
							<span className="inline-block bg-green-200 px-2 py-1 m-2 rounded-lg">
								<span className="font-bold">
									{item.assessments.carbon.footprint} kg eq.
								</span>
							</span>{" "}
							(Top {100 - item.assessments.carbon.footprint_pctile * 100}%)
						</p>
						<p className="mt-1">Price: ${item.price}</p>
						<p className="mt-1">
							Beaker Score:
							<span className="inline-block bg-green-200 px-2 py-1 m-2 rounded-lg">
								<span className="font-bold">
									{item.assessments.holistic.score}
								</span>
							</span>{" "}
							(Top {100 - item.assessments.holistic.score_pctile * 100}%)
						</p>
					</div>
				</div>
			) : (
				<p>Loading...</p>
			)}
		</div>
	);
};

export default ProductPassport;
