import React from "react";

interface DescriptionProps {
	text: string;
}

const Description: React.FC<DescriptionProps> = ({ text }) => {
	return <p className="text-lg my-4">{text}</p>;
};

export default Description;
