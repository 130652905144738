import React, { useState } from "react";
import Item from "../types/Item";
import "../App.css";

const ImpactBadge: React.FC<{ category: string; item: Item }> = ({
	category,
	item,
}) => {
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	const [isExpanded, setIsExpanded] = useState(false);
	const [hoveredPill, setHoveredPill] = useState<number | null>(null);

	const handleMouseEnter = () => {
		setIsPopoverOpen(true);
	};

	const handleMouseLeave = () => {
		setIsPopoverOpen(false);
		setIsExpanded(false);
	};

	const handleExpandClick = () => {
		setIsExpanded(!isExpanded);
	};

	const pills = Array.from({ length: 20 }, (_, index) => index);

	return (
		<div
			className="relative inline-block"
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			{category === "Carbon" && (
				<div>
					<div className="bg-white text-black px-4 mx-4 py-2 rounded-lg flex items-center border border-gray-300">
						<div className="flex flex-col justify-center items-center">
							<span className="font-bold text-xl">{category}</span>
							<span className="text-sm font-medium">Impact Percentile</span>
						</div>
						<div className="ml-4 bg-green-200 px-4 py-2 rounded-lg flex items-center justify-center">
							<span className="font-bold text-xl">
								{item.assessments.carbon.footprint_pctile * 100}%
							</span>
						</div>
					</div>

					{isPopoverOpen && (
						<div className="absolute top-18 left-1/2 transform -translate-x-1/2 w-64 bg-white border border-gray-200 rounded-lg shadow-lg p-4 z-10">
							<p className="text-gray-700 mb-2">
								This product’s {category.toLowerCase()} impact is lower than{" "}
								<span className="font-bold">
									{item.assessments.carbon.footprint_pctile * 100}%
								</span>{" "}
								of comparable products. Here’s where it places:
							</p>
							<div className="flex justify-center items-center mt-8 h-8">
								{pills.map((pill) => (
									<div
										key={pill}
										className={`relative w-4 rounded-full mx-0.5 ${
											pill === hoveredPill ? "h-6" : "h-5"
										} ${
											pill < item.assessments.carbon.footprint_pctile * 20
												? "bg-green-600"
												: "bg-gray-300"
										}`}
										onMouseEnter={() => setHoveredPill(pill)}
										onMouseLeave={() => setHoveredPill(null)}
									>
										{hoveredPill === pill &&
											pill <=
												item.assessments.carbon.footprint_pctile * 20 * 1.1 &&
											pill >=
												item.assessments.carbon.footprint_pctile * 20 * 0.9 && (
												<div className="absolute top-[-7.5rem] left-1/2 transform -translate-x-1/2 w-24 p-2 bg-white border border-gray-200 rounded-lg shadow-lg text-center">
													<img src={item.image_url} alt={item.name}></img>
												</div>
											)}
									</div>
								))}
							</div>
							<div className="mt-2 flex justify-between text-sm text-gray-500">
								<span>0%</span>
								<span>99%</span>
							</div>
							<p className="text-sm text-gray-600 mt-4">
								<div>
									Est CO<sub>2</sub>:{" "}
									<span className="inline-block bg-green-200 px-2 py-1 m-2 rounded-lg">
										<span className="font-bold">
											{item.assessments.carbon.footprint} kg eq.
										</span>
									</span>
								</div>
							</p>
							<button onClick={handleExpandClick} className="mt-2 ">
								{isExpanded
									? `Collapse ${category} Breakdown ▲`
									: `Expand ${category} Breakdown ▼`}
							</button>
							{isExpanded && (
								<div className="mt-4 text-sm text-gray-800">
									<p>{category} impact factors:</p>
									{item.assessments.carbon.breakdown.materials && (
										<div>
											{item.assessments.carbon.breakdown.materials * 100}%
											materials
										</div>
									)}
									{item.assessments.carbon.breakdown.manufacturing && (
										<div>
											{item.assessments.carbon.breakdown.manufacturing * 100}%
											manufacturing
										</div>
									)}
									{item.assessments.carbon.breakdown.shipping && (
										<div>
											{item.assessments.carbon.breakdown.shipping * 100}%
											shipping
										</div>
									)}
									{item.assessments.carbon.breakdown.usage && (
										<div>
											{item.assessments.carbon.breakdown.usage * 100}% usage
										</div>
									)}
									{item.assessments.carbon.breakdown.end_of_life && (
										<div>
											{item.assessments.carbon.breakdown.end_of_life * 100}% end
											of life
										</div>
									)}
								</div>
							)}
						</div>
					)}
				</div>
			)}
			{category === "Water" && (
				<div>
					<div className="bg-white text-black px-4 mx-4 py-2 rounded-lg flex items-center border border-gray-300">
						<div className="flex flex-col justify-center items-center">
							<span className="font-bold text-xl">{category}</span>
							<span className="text-sm font-medium">Impact Percentile</span>
						</div>
						<div className="ml-4 bg-green-200 px-4 py-2 rounded-lg flex items-center justify-center">
							<span className="font-bold text-xl">
								{item.assessments.water.footprint_pctile * 100}%
							</span>
						</div>
					</div>

					{isPopoverOpen && (
						<div className="absolute top-18 left-1/2 transform -translate-x-1/2 w-64 bg-white border border-gray-200 rounded-lg shadow-lg p-4 z-10">
							<p className="text-gray-700 mb-2">
								This product’s {category.toLowerCase()} impact is lower than{" "}
								<span className="font-bold">90%</span> of comparable products.
								Here’s where it places:
							</p>
							<div className="flex justify-center items-center mt-8 h-8">
								{pills.map((pill) => (
									<div
										key={pill}
										className={`relative w-4 rounded-full mx-0.5 ${
											pill === hoveredPill ? "h-6" : "h-5"
										} ${
											pill < item.assessments.water.footprint_pctile * 20
												? "bg-green-600"
												: "bg-gray-300"
										}`}
										onMouseEnter={() => setHoveredPill(pill)}
										onMouseLeave={() => setHoveredPill(null)}
									>
										{hoveredPill === pill && pill % 2 === 0 && (
											<div className="absolute top-[-2.6rem] left-1/2 transform -translate-x-1/2 w-24 p-2 bg-white border border-gray-200 rounded-lg shadow-lg text-center">
												{pill * 5}%
											</div>
										)}
									</div>
								))}
							</div>
							<div className="mt-2 flex justify-between text-sm text-gray-500">
								<span>0%</span>
								<span>99%</span>
							</div>
							<p className="text-sm text-gray-600 mt-4">
								{category === "Water" && (
									<div>
										{" "}
										Est Water Usage:{" "}
										<span className="inline-block bg-green-200 px-2 py-1 m-2 rounded-lg">
											<span className="font-bold">
												{item.assessments.water.footprint} L eq.
											</span>
										</span>
									</div>
								)}
							</p>
							<button onClick={handleExpandClick} className="mt-2 ">
								{isExpanded
									? `Collapse ${category} Breakdown ▲`
									: `Expand ${category} Breakdown ▼`}
							</button>
							{isExpanded && (
								<div className="mt-4 text-sm text-gray-800">
									<p>{category} impact factors:</p>
									{item.assessments.water.breakdown.materials && (
										<div>
											{item.assessments.water.breakdown.materials * 100}%
											materials
										</div>
									)}
									{item.assessments.water.breakdown.manufacturing && (
										<div>
											{item.assessments.water.breakdown.manufacturing * 100}%
											manufacturing
										</div>
									)}
									{item.assessments.water.breakdown.shipping && (
										<div>
											{item.assessments.water.breakdown.shipping * 100}%
											shipping
										</div>
									)}
									{item.assessments.water.breakdown.usage && (
										<div>
											{item.assessments.water.breakdown.usage * 100}% usage
										</div>
									)}
									{item.assessments.water.breakdown.end_of_life && (
										<div>
											{item.assessments.water.breakdown.end_of_life * 100}% end
											of life
										</div>
									)}
								</div>
							)}
						</div>
					)}
				</div>
			)}
			{category === "Social" && (
				<div>
					<div className="bg-white text-black px-4 mx-4 py-2 rounded-lg flex items-center border border-gray-300">
						<div className="flex flex-col justify-center items-center">
							<span className="font-bold text-xl">{category}</span>
							<span className="text-sm font-medium">Impact Percentile</span>
						</div>
						<div className="ml-4 bg-green-200 px-4 py-2 rounded-lg flex items-center justify-center">
							<span className="font-bold text-xl">
								{item.assessments.social.footprint_pctile * 100}%
							</span>
						</div>
					</div>

					{isPopoverOpen && (
						<div className="absolute top-18 left-1/2 transform -translate-x-1/2 w-64 bg-white border border-gray-200 rounded-lg shadow-lg p-4 z-10">
							<p className="text-gray-700 mb-2">
								This product’s {category.toLowerCase()} impact is lower than{" "}
								<span className="font-bold">90%</span> of comparable products.
								Here’s where it places:
							</p>
							<div className="flex justify-center items-center mt-8 h-8">
								{pills.map((pill) => (
									<div
										key={pill}
										className={`relative w-4 rounded-full mx-0.5 ${
											pill === hoveredPill ? "h-6" : "h-5"
										} ${
											pill < item.assessments.social.footprint_pctile * 20
												? "bg-green-600"
												: "bg-gray-300"
										}`}
										onMouseEnter={() => setHoveredPill(pill)}
										onMouseLeave={() => setHoveredPill(null)}
									>
										{hoveredPill === pill && pill % 2 === 0 && (
											<div className="absolute top-[-2.6rem] left-1/2 transform -translate-x-1/2 w-24 p-2 bg-white border border-gray-200 rounded-lg shadow-lg text-center">
												{pill * 5}%
											</div>
										)}
									</div>
								))}
							</div>
							<div className="mt-2 flex justify-between text-sm text-gray-500">
								<span>0%</span>
								<span>99%</span>
							</div>
							<p className="text-sm text-gray-600 mt-4">
								{category === "Social" && (
									<div>
										{" "}
										Social Impact Score:{" "}
										<span className="inline-block bg-green-200 px-2 py-1 m-2 rounded-lg">
											<span className="font-bold">
												{item.assessments.social.footprint} / 10
											</span>
										</span>
									</div>
								)}
							</p>
							<button onClick={handleExpandClick} className="mt-2 ">
								{isExpanded
									? `Collapse ${category} Factors ▲`
									: `Expand ${category} Factors ▼`}
							</button>
							{isExpanded && (
								<div className="mt-4 text-sm text-gray-800">
									<p>{category} impact factors:</p>
									<li>GOTS Certified Cotton</li>
									<li>B Corp Certified</li>
								</div>
							)}
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default ImpactBadge;
