import React from "react";
import Item from "../types/Item";
import ProductPassport from "./ProductPassport";
import ImpactBadge from "./ImpactBadge";

interface ScrollingSectionProps {
	item: Item;
	passport: boolean;
}

const cats = ["Carbon", "Water", "Social"];

const ScrollingSection: React.FC<ScrollingSectionProps> = ({
	item,
	passport,
}) => {
	return (
		<div>
			{passport ? (
				<div className="bg-gray-100 py-0 px-8 flex rounded">
					<ProductPassport key={item.id} item={item} />
				</div>
			) : (
				<div className="flex bg-gray-100 py-4 px-8 rounded">
					{cats.map((cat) => (
						<ImpactBadge category={cat} item={item} />
					))}
				</div>
			)}
		</div>
	);
};

export default ScrollingSection;
